import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query Testimonials {
      allRestApiApiTestimonials {
        edges {
          node {
            details {
              location
              name
              text
            }
          }
        }
      }
    }
  `)

  console.log('data', data)

  return (
    <main>
      <h2>Testimonials</h2>
    </main>
  )
}

export default Testimonials
